import React, {Component} from "react"
import {Layer, Stage, Circle, Text, TextPath, Arc} from "react-konva";

class ALChart extends Component {
    constructor(props) {
        super(props);
    }

    nodeNames = ["ALMobile", "Production", "Time", "Daily Diary", "Subcontract", "Activity Log", "Extras", "Signatures", "Equipment", "Work Orders"];
    konva = {
        "layer": {
            text: {
                ref: {}
            },
            shapes: {
                ref: {}
            }
        },
        "ALMobile": {
            Text: {
                ref: {}
            },
            Shape: {
                ref: {}
            }
        },
        "Production": {
            Text: {
                description: "This application allows you to track and analyze job progress, receive real-time updates about projects, and compare actual progress to projected budgets to improve your organization’s project completions.",
                ref: {}
            },
            Shape: {
                fill: "#00aeef",
                ref: {}
            }
        },
        "Time": {
            Text: {
                description: "This application allows you to quickly and easily enter employee time with the ability to attached additional pertinent information with the below ALMobile Modules.",
                ref: {}
            },
            Shape: {
                fill: "#0061a2",
                ref: {}
            }
        },
        "Daily Diary": {
            Text: {
                description: "Track job incidents, progress, and pull in weather information in real-time wherever your jobsite is located for project delays and more. Diary entries meet legal requirements and are collected in the home office daily.",
                ref: {}
            },
            Shape: {
                fill: "#00aeef",
                ref: {}
            }
        },
        "Subcontract": {
            Text: {
                description: "Track any subcontractor activity by project level, and capture work performance details of hired subcontractors. Resolve claims and conflicts for specific dates and so much more.",
                ref: {}
            },
            Shape: {
                fill: "#00aeef",
                ref: {}
            }
        },
        "Activity Log": {
            Text: {
                description: "Stay up-to-date on outstanding jobsite requirements and activity with this great reporting tool. You can create, track, filter, search, and update jobsite action items, RFI’s or additional needs that can be configured, documented, and tracked to completion all in one place.",
                ref: {}
            },
            Shape: {
                fill: "#00aeef",
                ref: {}
            }
        },
        "Extras": {
            Text: {
                description: "This feature provides value-added benefits in the tracking of data. With the growing demand of advanced tracking requirements, the Extra Module allows you to create user-definable fields, and track and record items such as per diems, expenses, safety meetings and brings you the flexibility to capture almost any type of additional information needed from the field.",
                ref: {}
            },
            Shape: {
                fill: "#0061a2",
                ref: {}
            }
        },
        "Signatures": {
            Text: {
                description: "Sign off on time entries and collect employee signatures directly on your mobile device. Always be compliant with state and government agency requirements for captured signatures, and approvals are compared against signatures on file for validation.",
                ref: {}
            },
            Shape: {
                fill: "#0061a2",
                ref: {}
            }
        },
        "Equipment": {
            Text: {
                description: "Collect, maintain, and track meter readings and maintenance records, and even generate time against pieces of equipment. Easily automate maintenance checklists while instantly sending data for each piece of equipment back to your accounting system.",
                ref: {}
            },
            Shape: {
                fill: "#0061a2",
                ref: {}
            }
        },
        "Work Orders": {
            Text: {
                description: "Create electronic work orders in the field and add additional fields to collect data supporting the work being done. Generate reports by date range and job number for backup to customer invoices while instantly transferring work order data to your main accounting system.",
                ref: {}
            },
            Shape: {
                fill: "#0061a2",
                ref: {}
            }
        }
    };

    state = {
        selectedNode: {
            nodeName: "",
            nodeText: ""
        },
        canvas: {
            width: 0,
            height: 0,
        },
        "ALMobile": {
            Text: {
                width: 0,
                height: 0
            }
        },
        "Production": {
            Text: {
                width: 0,
                height: 0
            }
        },
        "Time": {
            Text: {
                width: 0,
                height: 0
            }
        },
        "Daily Diary": {
            Text: {
                width: 0,
                height: 0
            }
        },
        "Subcontract": {
            Text: {
                width: 0,
                height: 0
            }
        },
        "Activity Log": {
            Text: {
                width: 0,
                height: 0
            }
        },
        "Extras": {
            Text: {
                width: 0,
                height: 0
            }
        },
        "Signatures": {
            Text: {
                width: 0,
                height: 0
            }
        },
        "Equipment": {
            Text: {
                width: 0,
                height: 0
            }
        },
        "Work Orders": {
            Text: {
                width: 0,
                height: 0
            }
        }
    };

    getStageDimensions = () => {
        const {parent} = this.props,
            parentElem = document.getElementById(parent);
        this.setState({
            canvas: {
                width: parentElem.offsetWidth,
                height: (parentElem.offsetWidth > 350) ? 350 : parentElem.offsetWidth
            }
        })

    };

    setTextValues = nodeName => {
        const {ref: nodeRef} = this.konva[nodeName].Text,
            {Text: text} = this.state[nodeName],
            textWidth = nodeRef.getTextWidth(),
            textHeight = nodeRef.height();
        text.width = textWidth;
        this.setState({
            [nodeName]: {
                Text: {
                    width: textWidth,
                    height: textHeight
                }
            }
        });
    };

    setNodeRef = (nodeName, nodeRef, nodeType) => {
        this.konva[nodeName][nodeType].ref = nodeRef;
    };

    updateNodes = _ => {
        for(let i = 0; i < this.nodeNames.length; i++){
            this.setTextValues(this.nodeNames[i]);
        }
    };

    componentDidMount(){
        this.getStageDimensions();
        this.updateNodes();
        window.addEventListener("resize", this.getStageDimensions);
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.getStageDimensions);
    }

    updatedSelected(selectedElem) {
        const {shapes, text} = this.konva.layer;
        if(this.state.selectedNode.nodeName){
            const prevSelected = this.konva[this.state.selectedNode.nodeName];
            prevSelected.Shape.ref.fill(prevSelected.Shape.fill);
            prevSelected.Text.ref.fill("#FFFFFF");
            shapes.ref.draw();
            text.ref.draw();
        }
        if(selectedElem === this.state.selectedNode.nodeName){
            this.setState({
                selectedNode: {
                    nodeName: "",
                    nodeText: ""
                }
            });
            this.props.clickCallback(false);
            return;
        }
        this.props.clickCallback(true);
        const {Shape, Text} = this.konva[selectedElem];
        Shape.ref.fill("white");
        Text.ref.fill("#0061a2");
        shapes.ref.draw();
        text.ref.draw();
        this.setState({
            selectedNode: {
                nodeName: selectedElem,
                nodeText: Text.description
            }
        })
    }

    render() {
        const {canvas, selectedNode} = this.state;
        return(
            <>
                <div className={"ALChartTitle"}>
                    Our Application Structure
                </div>
                <div className={"ALChartSubTitle"}>
                    Spend less time hunting things down and more time getting things done.<br/>
                    Organize your field data, process payroll in seconds, and track everything in one place.
                </div>
                <Stage container={this.props.parent} height={canvas.height} width={canvas.width}>
                    <Layer
                        ref={node => this.setNodeRef("layer", node, "shapes")}
                    >
                        <Circle
                            ref={node => this.setNodeRef("ALMobile", node, "Shape")}
                            x = {canvas.width/2}
                            y = {canvas.height/2}
                            radius={75}
                            fillGradientStartPointX={180}
                            fillLinearGradientEndPointX={210}
                            fillLinearGradientColorStops ={[0, '#1b3667', 1, '#00aeef']}
                        />
                        <Arc
                            ref={node => this.setNodeRef("Production", node, "Shape")}
                            Name={"Production"}
                            clockwise={true}
                            x = {canvas.width/2}
                            y = {canvas.height/2}
                            innerRadius= {120}
                            outerRadius= {74}
                            angle = {180}
                            fill={this.konva["Production"].Shape.fill}
                            stroke="#49A4D3"
                            onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                            onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <Arc
                            ref={node => this.setNodeRef("Time", node, "Shape")}
                            Name={"Time"}
                            x = {canvas.width/2}
                            y = {canvas.height/2}
                            innerRadius= {120}
                            outerRadius= {74}
                            angle = {180}
                            fill={this.konva["Time"].Shape.fill}
                            stroke={this.konva["Time"].Shape.fill}
                            onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                            onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <Arc
                            ref={node => this.setNodeRef("Activity Log", node, "Shape")}
                            Name={"Activity Log"}
                            clockwise={true}
                            x = {canvas.width/2}
                            y = {canvas.height/2}
                            rotation={0}
                            innerRadius= {122}
                            outerRadius= {160}
                            angle = {300}
                            fill={this.konva["Activity Log"].Shape.fill}
                            stroke="white"
                            onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                            onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <Arc
                            ref={node => this.setNodeRef("Subcontract", node, "Shape")}
                            Name={"Subcontract"}
                            clockwise={true}
                            x = {canvas.width/2}
                            y = {canvas.height/2}
                            rotation={300}
                            innerRadius= {122}
                            outerRadius= {160}
                            angle = {300}
                            fill={this.konva["Subcontract"].Shape.fill}
                            stroke="white"
                            onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                            onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <Arc
                            ref={node => this.setNodeRef("Daily Diary", node, "Shape")}
                            Name={"Daily Diary"}
                            clockwise={true}
                            x = {canvas.width/2}
                            y = {canvas.height/2}
                            rotation={600}
                            innerRadius= {122}
                            outerRadius= {160}
                            angle = {300}
                            fill={this.konva["Daily Diary"].Shape.fill}
                            stroke="white"
                            onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                            onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <Arc
                            ref={node => this.setNodeRef("Extras", node, "Shape")}
                            Name={"Extras"}
                            x = {canvas.width/2}
                            y = {canvas.height/2}
                            innerRadius= {122}
                            outerRadius= {160}
                            angle = {45}
                            fill={this.konva["Extras"].Shape.fill}
                            stroke="white"
                            onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                            onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <Arc
                            ref={node => this.setNodeRef("Signatures", node, "Shape")}
                            Name={"Signatures"}
                            x = {canvas.width/2}
                            y = {canvas.height/2}
                            innerRadius= {122}
                            outerRadius= {160}
                            rotation={45}
                            angle = {45}
                            fill={this.konva["Signatures"].Shape.fill}
                            stroke="white"
                            onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                            onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <Arc
                            ref={node => this.setNodeRef("Equipment", node, "Shape")}
                            Name={"Equipment"}
                            x = {canvas.width/2}
                            y = {canvas.height/2}
                            innerRadius= {122}
                            outerRadius= {160}
                            rotation={90}
                            angle = {45}
                            fill={this.konva["Equipment"].Shape.fill}
                            stroke="white"
                            onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                            onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <Arc
                            ref={node => this.setNodeRef("Work Orders", node, "Shape")}
                            Name={"Work Orders"}
                            x = {canvas.width/2}
                            y = {canvas.height/2}
                            innerRadius= {122}
                            outerRadius= {160}
                            rotation={135}
                            angle = {45}
                            fill={this.konva["Work Orders"].Shape.fill}
                            stroke="white"
                            onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                            onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                            onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                    </Layer>
                    <Layer
                        ref={node => this.setNodeRef("layer", node, "text")}
                    >
                        <Text Name="ALMobile" text="ALMobile" fill="white" fontSize={28}
                              x={(canvas.width-this.state["ALMobile"].Text.width)/2} y={(canvas.height - this.state["ALMobile"].Text.height)/2}
                              ref={node => this.setNodeRef("ALMobile", node, "Text")}
                        />
                        <Text Name="Production" text="Production" align="center" fill="white" fontSize={18}
                              x={(canvas.width-this.state["Production"].Text.width)/2} y={(canvas.height/2)-100}
                              ref={node => {this.setNodeRef("Production", node, "Text")}}
                              onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                              onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                              onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                              onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <Text Name="Time" text="Time" align="center" fill="white" fontSize={18}
                              x={(canvas.width-this.state["Time"].Text.width)/2} y={(canvas.height/2)+85}
                              ref={node => this.setNodeRef("Time", node, "Text")}
                              onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                              onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                              onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                              onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <TextPath Name="Subcontract" text="Subcontract" align="center" fill="white" fontSize={18}
                                  x={(canvas.width-this.state["Subcontract"].Text.width)/2} y={(canvas.height/2) - 128}
                                  ref={node => this.setNodeRef("Subcontract", node, "Text")}
                                  data={`M0 0 
                                         a ${this.state["Subcontract"].Text.width} ${this.state["Subcontract"].Text.width}
                                         0 0 1 
                                         ${this.state["Subcontract"].Text.width} 0`}
                                  onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                                  onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <TextPath Name="Activity Log" text="Activity Log" align="center" fill="white" fontSize={18}
                                  x={(canvas.width/2)+75} y={((canvas.height/2) - 150)}
                                  ref={node => this.setNodeRef("Activity Log", node, "Text")}
                                  data={`M-45 20
                                         q 125 25
                                         ${this.state["Activity Log"].Text.width+12} 175`}
                                  onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                                  onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <TextPath Name="Daily Diary" text="Daily Diary" align="center" fill="white" fontSize={18}
                                  x={(canvas.width/2)-140} y={(canvas.height/2)+135}
                                  ref={node => this.setNodeRef("Daily Diary", node, "Text")}
                                  data={`M -3 0
                                         c 0 -196 -20 -195 
                                         ${this.state["Daily Diary"].Text.width+100} -360`}
                                  onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                                  onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <TextPath Name="Extras" text="Extras" align="center" fill="white" fontSize={18}
                                  x={(canvas.width/2)+95} y={(canvas.height/2)}
                                  ref={node => this.setNodeRef("Extras", node, "Text")}
                                  data={`M 7 ${this.state["Extras"].Text.width+5}
                                         c 5 25 25 45 
                                         ${this.state["Extras"].Text.width-6} -${this.state["Extras"].Text.width+25}`}
                                  onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                                  onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <TextPath Name="Signatures" text="Signatures" align="center" fill="white" fontSize={18}
                                  x={(canvas.width/2)} y={(canvas.height/2)+130}
                                  ref={node => this.setNodeRef("Signatures", node, "Text")}
                                  data={`M 0 15
                                         q 110 -20                                   
                                         ${this.state["Signatures"].Text.width+10} -70`}
                                  onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                                  onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <TextPath Name="Equipment" text="Equipment" align="center" fill="white" fontSize={18}
                                  x={((canvas.width-(this.state["Equipment"].Text.width*2))/2)} y={(canvas.height/2) + 120}
                                  ref={node => this.setNodeRef("Equipment", node, "Text")}
                                  data={`M -5 -10}
                                         q 35 30
                                         ${this.state["Equipment"].Text.width+10} 34`}
                                  onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                                  onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                        <TextPath Name="Work Orders" text="Work Orders" align="center" fill="white" fontSize={16}
                                  x={(canvas.width/2)-160} y={(canvas.height/2)}
                                  ref={node => this.setNodeRef("Work Orders", node, "Text")}
                                  data={`M 15 9}
                                         q 9 55
                                         40 ${this.state["Work Orders"].Text.width}`}
                                  onClick={ e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onTap={e => this.updatedSelected(e.currentTarget.attrs.name)}
                                  onMouseEnter={ _ => document.body.style.cursor = "pointer"}
                                  onMouseLeave={ _ => document.body.style.cursor = "default"}
                        />
                    </Layer>
                </Stage>
                <div id={"chartResults"}>
                    {(selectedNode.nodeName) ? selectedNode.nodeText : "Click anywhere on the ALMobile Application Structure to learn about all of the modules and features we offer!"}
                </div>
            </>
        );
    }
}

export default ALChart;