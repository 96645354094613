import React, {useState} from "react";
import ALDialog from "./ALDialog";
import smoothScrollTo from "./smoothScroll";

const VideoRow = ({videoData}) => {
    const [dialog, toggleDialog] = useState(false);
    return (
        <>
            <div className={"videoRow"}>
                {
                    videoData
                }
                <div className={"extraInfoContainer"}>
                    <div
                        onClick={ _ => toggleDialog(true)}
                        className={"link btn"}
                    >
                        Download Free RFP Guide
                    </div>
                    <a className={"link btn"} href="mailto:marketing@austinlane.com" target="_top">Contact Us</a>
                </div>
            </div>
            {
                (dialog) &&
                <ALDialog
                    header={"Get Your Free RFP Guide"}
                    dialogType={"RFP"}
                    dialogFields={[
                        {
                            type: "input",
                            inputName: "First Name",
                            inputLabel: "First Name",
                            inputType: "text",
                            inputPlaceHolder: ""
                        },
                        {
                            type: "input",
                            inputLabel: "Last Name",
                            inputName: "Last Name",
                            inputType: "text",
                            inputPlaceHolder: ""
                        },
                        {
                            type: "input",
                            inputLabel: "Email*",
                            inputName: "Email",
                            inputType: "text",
                            inputPlaceHolder: "",
                            required: true
                        }
                    ]}
                    onClose={toggleDialog}
                    onSave={ _ => {
                        let element = document.createElement('a');
                        element.setAttribute("href", "/downloads/RFPDownload.pdf");
                        element.setAttribute("download", `ALMobileRFPGuide.pdf`);
                        element.style.display = 'none';
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                    }}
                />
            }
        </>
    )
};

const NumberRow = ({className, data, dataID, videoData, clickCallback}) => {
    return (
        <div className={`contentRow numberLine ${className}`}>
            <div className={`subContentRow`}>
                <div
                    className={`numberRow`}
                    data-id={dataID}
                >
                    <div
                        id={`row${dataID}`}
                        className={"number"}
                        onClick={ e => clickCallback(e.currentTarget.parentElement)}
                        onKeyUp={clickCallback}
                    >
                        {dataID}
                    </div>
                    {data}
                </div>
                {(videoData) ? <VideoRow videoData={videoData}/> : <></>}
            </div>
        </div>
    )
};

const NumberLine = ({rows}) => {
    const videoRow = rows.video;
    const scrollToRow = (row) => smoothScrollTo(row.offsetTop, 300);
    return (
        <>
            {
                rows.general.map((elem, index) => {
                    return <NumberRow
                        clickCallback={scrollToRow}
                        key={index}
                        data={elem.data}
                        dataID={index + 1}
                        videoData={(index === 3) ? videoRow : null}
                        className={(index === 3) ? "last" : ""}
                    />
                })
            }
        </>
    )
};

export default NumberLine;

