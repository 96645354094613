import React, { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import NestedContentRow from "../components/nestedContentRow";
import NumberLine from "../components/NumberLine";
import ALChart from "../components/ALMobileChart";
import FAQ from "../components/FAQ";

import ALTechVideo from "../images/ALMobileVideo.mp4";
import ALTechPoster from "../images/ALPosterGeneral.jpg";
import smoothScrollTo from "../components/smoothScroll";

const TimePage = () => {
    const imgData = useStaticQuery(graphql`
        query {
            fullClientDesktop: file(relativePath: { eq: "AllDevicesTimeBubble.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            fullClientMobile: file(relativePath: { eq: "ALLDevicesTime.png" }) {
                childImageSharp {
                    fluid(maxWidth: 376) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            arrowDownWhiteIcon: file(relativePath: { eq: "ArrowDownWhite.png" }) {
                childImageSharp {
                    fixed(width: 15) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            ipadGTE: file(relativePath: { eq: "IpadGTE.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            } 
            fullClientMonitor: file(relativePath: { eq: "FullClientDesktop2019.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            ateMobile: file(relativePath: { eq: "ATEMobile.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const mediaQuerySources = [
        {
            ...imgData.fullClientMobile.childImageSharp.fluid,
            media: `(max-width: 1366px)`
        },
        {
            ...imgData.fullClientDesktop.childImageSharp.fluid,
            media: `(min-width: 1367px)`,
        }
    ];
    const FAQData = [
        {
            question: "Is your software multi-lingual?",
            answer: ["Yes, we offer our software licenses in the following languages: English, French, and Spanish"]
        },
        {
            question: "What ERP do you integrate to?",
            answer: ["We integrate to most major ERP systems. Some of those systems include: AMSI, CGC, CMiC, COINS, Dexter & Chaney, Explorer, JD Edwards, Microsoft Dynamics, Oracle, Penta, SAGE, SAP, Viewpoint, and more. Don’t see your ERP/accounting system in this list? Not a problem! We are constantly growing the list of partners and vendors that we work with in order to provide the best integration experience for our clients."]
        },
        {
            question : "What industries do you serve?",
            answer: ["We serve a wide variety of industries working in any environment. Some of those industries include: Construction, Power, Environmental, Heavy Highway, Industrial, Mining, Oil & Gas, and Rail."]
        },
        {
            question : "What is included in your support services?",
            answer: [
                "ALMobile Support offers a unique ticket priority system, where our clients choose the priority of each ticket that they enter and when they would like a response. Included in our services, is our ALMobile Support Portal where clients can access current information about support, educational videos, and key contacts. You can also look for published materials on Austin Lane’s products and support with ease.",
                "Every two years, we also host an ALMobile Users Conference, complimentary for all of our clients. This exclusive two-day training is invaluable, as we provide an exclusive look into new and exciting features.  Several of our clients speak during the training sessions that are led by an Austin Lane team member.",
                "Lastly, we provide a monthly ALMobile Lunch and Learn sessions as well that are hosted by Account Representatives to help train users on our products in case you missed the user conferences."
            ]
        },
        {
            question : "Who are your technology partners?",
            answer: ["Having been in business since 1999, we have many established partnerships. Some of those partnerships include Apple, Android, Microsoft, Verizon, AT&T and Sprint; along with multiple ERPs."]
        },
        {
            question : "Can you support a company of my size?",
            answer: ["Austin Lane supports organizations of all sizes. We offer an ALMobile Professional and Enterprise edition, which allows us to extend ALMobile to organizations that typically have 200 remote personnel in the field or to others organizations with thousands of employees. ALMobile by design is an application that can be used by everyone, from the field employee to the home office administrator, and integrates to industry-specific ERPs. In a single installation of ALMobile, you are able to manage data across the entire organization so that jobs, equipment, and employees can be filtered by the specific business unit or office location."]
        },
        {
            question : "Is your software on the cloud?",
            answer: ["Yes, it is! The ALMobile Cloud model is a SaaS subscription, meaning you pay monthly for the software and is available in yearly contracts."]
        },
        {
            question : "What is your company’s industry experience?",
            answer: ["Since 1999, our mission right from the beginning has been to help the construction industry and our clients excel by providing them with world-class support and innovative features and functionalities within our software. We think of our clients as partners, as the development and design of our applications have been and continue to be heavily influenced by their feedback. After being in business for 20 years, we also pride ourselves on knowing what companies need before they need it, as we have seen too often what issues organizations in the construction industry experience without automating data collection. That’s why it’s been our goal to make the lives of our clients easier by streamlining their business processes and eliminating those problems they face every day."]
        },
        {
            question : "Why do I need to talk to someone before I buy?",
            answer: ["We want our clients to be successful with the ALMobile software, and having a quick conversation to ensure both parties are on the same page is the best way to do that."]
        },
        {
            question : "How long does implementation usually take?",
            answer: ["While every company is different, the average time between the initial ALMobile Kickoff Meeting and ‘Go Live’ date can be as little as four business days. In essence, we work as fast as your organization allows us to. All of the details and logistics of our implementation process are laid out in our ALMobile Implementation Guide, which all of our clients receive during the initial Kickoff Meeting."]
        },
        {
            question: "Can you handle complex payroll?",
            answer: ["Payroll is one of the more complex accounting processes within most companies, so we understand why you are asking this question. Austin Lane has a deep understanding of payroll, costing and ALMobile creates one unified and accurate source of time records. This completely eliminates all manual processes relating to the collection, manipulation and approval of time records for payroll processing. ALMobile is an enterprise solution that addresses the business needs of multi-company, multi-national, multi-union, multi-pay periods as well as multi-device support. The result is the Austin Lane team helps drives business process improvement and transformation in your organization."]
        }
    ];
    const numberRows = {
        general: [
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 1 }}
                        iconData={{
                            iconType: "fluid",
                            icon: imgData.ipadGTE.childImageSharp.fluid,
                            iconCaption: <div className={"nestIconCaption"}>ALMOBILE GRID TIME ENTRY&trade;</div>,
                            iconAlt: "ALField Worker Icon"
                        }}
                        textData={{
                            level1: "True Streamless Integration",
                            level2: "Experience a bi-directional interface that pulls and filters only the relevant information from your ERP"
                        }}
                        link={
                            <div className={"level3"}>
                                <Link
                                    className={"link btn"}
                                    to={`/events/`}
                                    state={{component: "webinars"}}
                                >
                                    Learn About Our Intergration
                                </Link>
                             </div>
                        }
                    />
            },
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 2 }}
                        iconData={{
                            icon: imgData.fullClientMonitor.childImageSharp.fluid,
                            iconAlt: "ALMobile Full Client Monitor",
                            iconType: "fluid",
                            iconCaption: <div className={"nestIconCaption"}>ALMOBILE FULL CLIENT&trade;</div>
                        }}
                        textData={{
                            level1: "Improve Profits and Performance",
                            level2: "Customize reports to analyze your most critical project, equipment, and employee data in the field or at the office"
                        }}
                        link={
                            <div className={"level3"}>
                                <Link className={"link btn"} to={`/events/`} state={{component: "webinars"}}>See Our Reporting Capabilites</Link>
                            </div>
                        }
                    />
            },
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 3 }}
                        iconData={{
                            iconType: "fluid",
                            icon: imgData.ateMobile.childImageSharp.fluid,
                            iconAlt: "ALMobile ATE",
                            iconCaption: <div className={"nestIconCaption"}>ALMOBILE ADVANCED TIME ENTRY&trade;</div>
                        }}
                        textData={{
                            level1: "Flexible and Simple for the Field",
                            level2: "Operate ALMobile 24/7 with or without an internet connection anywhere, on any device, and operating system. With multiple options and methods of time entry available, you can enter data the way you want easily and quickly"
                        }}
                        link={<div className={"level3"}>
                            <Link className={"link btn"} to={`/events/`} state={{component: "webinars"}}>Register for a Webinar to See How</Link>
                        </div>}
                    />
            },
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 4 }}
                        textData={{
                            level1: "Reap the Rewards",
                            level2: "ALMobile's technology provides measurable growth, efficiency, productivity, retention, and innovation"
                        }}
                    />
            }
        ],
        video:
            <video
                controls
                poster={ALTechPoster}
            >
                <source src={ALTechVideo}/>
            </video>
    };
    const [aboutActive, toggleActive] = useState(false);
    const startScroll = _ => {
        const row1 = document.querySelector(".numberRow[data-id='1']");
        smoothScrollTo(row1.offsetTop, 300);
    };
    const setAboutActive = (state) => toggleActive(state);

    return (
        <Layout pageID={"ALMobileTimeTracking"}>
            <SEO
                title="ALMobile Time Tracking"
                description={"ALMobile Time Tracking is a time, attendance, and equipment tracking tool that integrates with all ERP solutions. It is secure, stable and scalable for businesses of all sizes. Enter and approve employee hours, pull labor time reports, and process payroll with ease."}
            />
            <div className={"contentRow productOverview"}>
                <div className={"subContentRow"}>
                    <div className={"textContainer"}>
                        <div className={"ProductOverviewMainSubHeader"}>PRODUCT OVERVIEW</div>
                        <div className={"level1 text"}>
                            ALMobile Time Tracking
                        </div>
                        <div className={"level2 text"}>
                            ALMobile Time Tracking was designed to make employee time entry
                            easy and fast for remote personnel and crews, so you can focus on
                            your projects and get more done in less time. Enter time in seconds with
                            all of ALMobile's powerful and innovative technology.
                        </div>
                    </div>
                    <div className={"startOverviewContainer"}>
                        <div
                            className={"startOverviewBtnContainer"}
                            onClick={startScroll}
                            onKeyUp={startScroll}
                        >
                            <Img
                                className={"startOverviewBtn"}
                                fixed={imgData.arrowDownWhiteIcon.childImageSharp.fixed}
                                alt={"Start Btn"}
                                style={{ display: "block" }}
                            />
                        </div>
                        <div
                            className={"startOverviewLabel"}
                            onClick={startScroll}
                            onKeyUp={startScroll}
                        >
                            LEARN MORE
                        </div>
                    </div>
                    <Img
                        className={"imgContainer"}
                        style={{ display: "block" }}
                        fluid={mediaQuerySources}
                        imgAlt={"ALMobile Desktop"}
                    />
                </div>
            </div>
            <NumberLine rows={numberRows} />
            <div id={"ALChart"} className={`contentRow ${(aboutActive) ? "active" : ""}`}>
                <ALChart parent="ALChart" id={"timePageChart"} clickCallback={setAboutActive}/>
            </div>
            <FAQ QAData={FAQData} />
        </Layout>
    )
};

export default TimePage;
