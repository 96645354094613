import React, { useEffect, useRef, useState } from "react";
import Img from "gatsby-image";
import {useStaticQuery, graphql} from "gatsby";

const QA = function({question, answer, showAnswer}) {
    const arrowData = useStaticQuery(graphql`
        query {
            arrowDownBlue: file(relativePath: { eq: "ArrowDownBlue.png" }) {
                childImageSharp {
                    fixed(width: 20) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    const [answerShowing, toggleAnswer] = useState(false);
    return (
        <div className={`FAQContent`}>
            <div
                className={`FAQBtnContainer`}
                onClick={ _ => showAnswer(answerShowing, toggleAnswer)}
            >
                <Img
                    className={`FAQBtn ${(answerShowing) ? "active" : ""}`}
                    fixed={arrowData.arrowDownBlue.childImageSharp.fixed}
                    alt={"Blue Arrow"}
                    style={{display: "block"}}
                />
            </div>
            <div className={"FAQuestion"}>{question}</div>
            <div className={`FAQAnswerContainer ${(answerShowing) ? "active" : ""}`}>
                <ul className={`FAQAnswerList ${(answer.length > 1) ? "multiple" : "single"}`}>
                {
                    answer.map((elem,index, answerArray) => {
                        return <li key={index} className={`answerComp ${(answerArray.length > 1) ? "multiple" : "single"}`}>
                            {elem}
                        </li>
                    })
                }
                </ul>
            </div>
        </div>
    )
};

const FAQ = ({QAData}) => {
    let prevItem = {item: null, stateCallback: _ => ""};
    const showAnswer = function(currentItem, itemChangeCallback) {
        if(prevItem.item !== null){
            prevItem.stateCallback(!prevItem.item);
        }
        if(prevItem.item !== currentItem) {
            prevItem.item = !currentItem;
            prevItem.stateCallback = itemChangeCallback;
            itemChangeCallback(!currentItem);
        }
    };
    useEffect( _ => {
        return function() {
            /* Reset FAQ Display */
            prevItem.stateCallback(false);
            prevItem = {item: null, stateCallback: null};
        }
    });
    return (
        <div id={"FAQ"} className={"contentRow"}>
            <div className={"subContentRow"}>
                <div className={"FAQHeader"}>
                    Frequently Asked Questions
                </div>
                <div className={"FAQContainer"}>
                    {
                        QAData.map((elem, index) => {
                            return <QA
                                showAnswer={showAnswer}
                                key={index}
                                question={elem.question}
                                answer={elem.answer}
                            />
                        })
                    }
                </div>
            </div>
        </div>
    )
};

export default FAQ;